<template>
<div>
  <div class="d-flex flex-column flex-root background">
    <!--begin::Page-->
    <div class="d-flex flex-row flex-column-fluid page">
      <!--begin::Wrapper-->
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <core-header></core-header>
        <!--begin::Content-->
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <!--begin::Entry-->
          <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
              <core-view/>
            </div>
            <!--end::Container-->
          </div>
          <!--end::Entry-->
        </div>
        <!--end::Content-->
        <!--<core-footer></core-footer>-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
  <!--end::Main-->
  <core-quick-user></core-quick-user>
</div>
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'

import PerfectScrollbar from 'perfect-scrollbar';

import flaticon from '@/assets/fonts/flaticon/flaticon.css'
import flaticon2 from '@/assets/fonts/flaticon2/flaticon.css'
import keenthemes from '@/assets/fonts/keenthemes-icons/font/ki.min.rtl.css'

export default {
  name: 'lk',
  data() {
    return {
      ps: null,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticated',
      token: 'user/token',
      identity: 'user/identity',
    }),
    title() {
      return this.$route.meta.title || ''
    },
    subtitle() {
      return this.$route.meta.subtitle || ''
    },
    font_ki() {
      return font_ki
    },
    // font_flaticon() {
    //   return font_flaticon
    // },
    // font_flaticon2() {
    //   return font_flaticon2
    // },
  },
  created() {
    this.ps = new PerfectScrollbar('#apps');
  },
  watch: {
    $route(to, from) {
      setTimeout(() => {
        this.ps.update();
      }, 1000);
    },
  },
  mounted() {
    setTimeout(() => {
      this.ps.update();
    }, 1000)
  },
  beforeMount() {
    this.ps.update();
  },
  methods: {
    ...mapActions('quick_user', [
      'toggleQuickUser'
    ])
  }
    // middleware: './modules/user/src/middleware/authenticated',
}
</script>

<style lang="scss">
  @import '@/assets/scss/theme.scss';
  @import '../../node_modules/vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

#apps {
  height: 100%;
  width: 100%;
  overflow: visible !important;
  margin: 0;
  padding: 0;
}

.ps__rail-x, .ps__rail-y {
  opacity: 1 !important;
  z-index: 2;
}

.ps__rail-y {
  left: unset !important;
  right: 0 !important;
}

.ps__thumb-x, .ps__thumb-y {
  background-color: #919191 !important;
}

.ps__thumb-x {
  bottom: 0px !important;
}

.ps__thumb-y {
  right: 0px !important;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  background-color: transparent !important;
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
</style>
